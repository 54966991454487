@import "colors";
@import "fonts";

ul, li, p {
	margin: 0;
	padding: 0;
}

ul {
	list-style-type: none;
}

.node-full,
#block-mdblocks-google-map,
#block-mdblocks-banner-content {

	ul {
		list-style-type: disc;
		list-style-position: inside;
		padding-left: 20px;

		li {
			margin-bottom: 5px;
		}

	}

}

.node-full ul,
#block-mdblocks-banner-content ul {
	margin-bottom: 30px;
}

.node-full .ya-share2__list {
	padding-left: 0;
}

body {

	font-family: $font-regular;
	background-color: $color-body-bg;
	color: $color-font;

}

strong, b, h1, h2 {
	font-family: $font-bold;
	font-weight: normal;
}

h1, h2 {
	display: inline-block;
}

a, a:visited {
	text-decoration: none;
	color: $color-a-default;
	transition: color 0.3s ease-in-out;
}

a.active, a:hover, a:active {
	color: $color-a-hover;
}

a.link-green {
	color: $color-a-hover;

	&:hover {
		text-decoration: underline;
	}
}

.node-full,
#block-mdblocks-banner-content {

	a, a:visited {
		color: $color-highlight;
		text-decoration: none;
	}

	a.active, a:hover, a:active {
		color: $color-highlight;
		text-decoration: underline;
	}

}

.main-menu {

	a {
		text-transform: uppercase;
    font-family: $font-semibold;
	}

}

.footer {

	.main-menu {

		a {
			color: $color-font-footer;
		}

		a.active, a:hover, a:active {
			color: $color-a-hover;
		}

	}

}


.site-slogan {

	a, a:visited {
		text-decoration: underline;
		color: $color-a-slogan-default;
		text-transform: none;
		transition: color 0.3s ease-in-out;
	}

	a:hover, a:active {
		color: $color-a-slogan-hover;
	}

}

.header {

	.logo {
		display: block;
		position: relative;
		float: left;
		width: 173px;
		height: 48px;

		img {
			display: block;
			width: 100%;
		}

	}

	&.new-year {
		.logo {
			width: 240px;
			height: 78px;
			position: absolute;
			left: -20px;
			margin-top: -18px;
		}
		.site-slogan {
			margin-left: 214px;
		}
	}

	.new-year-hat {
		background: url(../../images/new-year-hat.png) no-repeat 0 0 transparent;
		width: 111px;
		height: 108px;
		position: absolute;
		margin-top: -45px;
		margin-left: -50px;
		pointer-events: none;
		z-index: 3;
	}

}

.footer {

	.slogan {
    font-size: 12px;
    float: left;
    padding-left: 20px;
    color: #fff;
  }

	.logo {
		display: block;
		position: relative;
		float: left;
		width: 173px;
		height: 48px;

		img {
			display: block;
			width: 100%;
		}

	}

}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	font-size: 0;
	clear: both;
	visibility: hidden;
}

table {
	margin: 0;
	padding: 0;
	width: 100%;

	th {
		font-weight: normal;
	}

}

div {
	box-sizing: border-box;
}

hr {
	border: none;
	border-bottom: 1px solid $color-border;
}
