@font-face {
  font-family: "opensans-regular";
  src: url("../../fonts/opensans-regular.woff2") format('woff2'),
    url("../../fonts/opensans-regular.woff") format('woff'),
    url("../../fonts/opensans-regular.ttf") format('truetype'),
    url("../../fonts/opensans-regular.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "opensans-light";
  src: url("../../fonts/opensans-light.woff2") format('woff2'),
    url("../../fonts/opensans-light.woff") format('woff'),
    url("../../fonts/opensans-light.ttf") format('truetype'),
    url("../../fonts/opensans-light.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "opensans-semibold";
  src: url("../../fonts/opensans-semibold.woff2") format('woff2'),
    url("../../fonts/opensans-semibold.woff") format('woff'),
    url("../../fonts/opensans-semibold.ttf") format('truetype'),
    url("../../fonts/opensans-semibold.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "opensans-bold";
  src: url("../../fonts/opensans-bold.woff2") format('woff2'),
    url("../../fonts/opensans-bold.woff") format('woff'),
    url("../../fonts/opensans-bold.ttf") format('truetype'),
    url("../../fonts/opensans-bold.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "roboto-regular";
  src: url("../../fonts/roboto-regular.woff2") format('woff2'),
    url("../../fonts/roboto-regular.woff") format('woff'),
    url("../../fonts/roboto-regular.ttf") format('truetype'),
    url("../../fonts/roboto-regular.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "roboto-light";
  src: url("../../fonts/roboto-light.woff2") format('woff2'),
    url("../../fonts/roboto-light.woff") format('woff'),
    url("../../fonts/roboto-light.ttf") format('truetype'),
    url("../../fonts/roboto-light.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "roboto-medium";
  src: url("../../fonts/roboto-medium.woff2") format('woff2'),
    url("../../fonts/roboto-medium.woff") format('woff'),
    url("../../fonts/roboto-medium.ttf") format('truetype'),
    url("../../fonts/roboto-medium.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "roboto-bold";
  src: url("../../fonts/roboto-bold.woff2") format('woff2'),
    url("../../fonts/roboto-bold.woff") format('woff'),
    url("../../fonts/roboto-bold.ttf") format('truetype'),
    url("../../fonts/roboto-bold.svg") format('svg');
  font-style: normal;
  font-weight: normal;
}

$font-regular: opensans-regular, sans-serif;
$font-bold: opensans-bold, sans-serif;
$font-semibold: opensans-semibold, sans-serif;
$font-light: roboto-light, sans-serif;
$font-medium: roboto-medium, sans-serif;
$font2-bold: opensans-bold, sans-serif;
