
$color-body-bg: #f0f0f0;

$color-page-bg: #fff;

$color-popup-bg: #f7f8fb;

$color-bg-hover: #eee;

$color-footer-bg: #202125;

$color-font-footer: #fff;

$color-font-subfooter: #6b6c70;

$color-font-gray: #6b6c70;

$color-checkbox-bg: $color-footer-bg;

$color-active-bg: #e6e6e6;

$color-border: #d6d4d4;

$color-font: #333333;

$color-title: #222;

$color-a-default: #333333;

$color-a-hover: #56af47;

$color-a-slogan-default: #f55d50;

$color-highlight-red: #f55d50;
$color-highlight-red-less: #f5dcd8;
$color-highlight-less: #e2f3e9;

$color-a-slogan-hover: #d52d20;


$color-highlight: #56af47;

$color-highlight-more: #66bf57;

$color-highlight-hover: #257e16;

$color-highlight-color: #fff;

$color-price: #333;

$color-price-discount: #d54d4d;

$color-popup-back: rgba(0,0,0, 0.5);

$color-dimmed: #999;
