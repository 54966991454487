@import "colors";
@import "fonts";

body {
  font-size: 14px;
}

.highlight {
  color: $color-highlight;
}

.highlight-red {
  color: $color-highlight-red;
}

.header {

  .site-slogan {
    position: absolute;
    margin-left: 300px;
    margin-top: 13px;
  }

  .slogan {
    font-size: 12px;
    float: left;
    padding-left: 20px;
    color: #fff;
    margin-top: -2px;
    line-height: 1.3;
  }

  .subscribe-button {
    background-color: $color-highlight;
    float: right;
    color: $color-highlight-color;
    padding: 12px 30px;
    border-radius: 22px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: $color-highlight-hover;
      color: $color-highlight-color;
    }

  }

  .mailto-link {
    float: right;
    margin-right: 20px;
    margin-top: 24px;
  }

}

.footer {

  .mailto-link {
    color: $color-highlight;
    text-decoration: underline;
  }

  .footer-indexes {
    position: absolute;
    margin-top: 90px;
    font-size: 13px;
  }

  .subsrcibe-button {
    background-color: $color-highlight;
    position: absolute;
    top: 80px;
    left: 190px;
    float: left;
    color: $color-highlight-color;
    padding: 12px 50px;
    border-radius: 22px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background-color: $color-highlight-hover;
      color: $color-highlight-color;
    }

  }

}

.header {

  .social-buttons {
    position: relative;
    vertical-align: top;
    text-align: right;
    min-width: 165px;

    .social-button {
      display: inline-block;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 20px;
      overflow: hidden;
      margin-left: 15px;
      font-size: 18px;
      color: #000;
      background-color: #fff;
      text-align: center;
      line-height: 38px;
      text-decoration: none;
      transition: background-color 0.1s linear, border 0.1s linear;

      &:hover {
        color: #fff;
        background-color: $color-highlight;
        border: 1px solid $color-highlight;
      }
    }

  }
}

.footer {

  .social-buttons {
    position: absolute;
    margin-top: 80px;

    .social-button {
      display: inline-block;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 20px;
      overflow: hidden;
      transition: background-color 0.1s linear;
      margin-right: 15px;
      font-size: 18px;
      color: $color-footer-bg;
      text-align: center;
      line-height: 38px;
      text-decoration: none;
      background-color: #fff;

      &:hover {
        color: #fff;
        background-color: $color-highlight;
        border: 1px solid $color-highlight;
      }

    }

  }
}


#block-mdblocks-story {
  margin-bottom: 70px;

  h2 {
    text-align: center;
    display: block;
  }

  .content {
    margin: 0 -15px;
    font-size: 16px;
    clear: both;
  }

  .view-story-row {
    width: 376px;
    float: left;
    margin: 0 12px 15px;
    padding-bottom: 15px;
    border-bottom: 8px solid transparent;
    min-height: 420px;
    transition: border-bottom 100ms linear;

    &:hover {
      border-bottom: 8px solid $color-highlight-less;
    }

    .view-theme {
      margin-top: 20px;
      text-transform: uppercase;
      color: $color-font-gray;
      font-size: 12px;
      font-family: $font-light;
      letter-spacing: 1px;
    }

    .view-title {
      margin-top: 20px;

      span {
        margin-right: 10px;
      }

    }

  }

  .view-menu {
    text-align: center;
    clear: both;

    .show-all {
      display: inline-block;
      border: 1px solid $color-highlight;
      background-color: $color-highlight;
      color: #fff;
      padding: 12px 30px;
      border-radius: 22px;
      text-transform: uppercase;
      transition: background-color 0.1s linear;

      &:hover {
        color: #fff;
        background-color: $color-highlight-hover;
      }

    }

  }

}

#block-block-36 {
  margin-top: 80px;

  p {
    display: block;
    margin: 0 0 20px;

    a {
      display: block;
      margin-left: 30px;
      float: left;

      &:first-child {
        margin-left: 0;
      }

    }

  }

}

#block-mdblocks-journal {
    clear: both;
}

#block-mdblocks-news {
  margin-bottom: 70px;
  text-align: center;

  .content {
    text-align: left;
    margin: 0 -10px;
    font-size: 16px;
    clear: both;
  }

  .view-story-row {
    width: 270px;
    float: left;
    margin: 0 15px 15px;
    padding-bottom: 15px;
    border-bottom: 8px solid transparent;
    min-height: 340px;
    transition: border-bottom 100ms linear;

    &:hover {
      border-bottom: 8px solid $color-highlight-less;
    }

    .view-theme {
      margin-top: 20px;
      text-transform: uppercase;
      color: $color-font-gray;
      font-size: 12px;
      font-family: $font-light;
      letter-spacing: 1px;
    }

    .view-title {
      margin-top: 20px;

      span {
        margin-right: 10px;
      }

    }

  }

  .view-menu {
    text-align: center;
    clear: both;

    .show-all {
      display: inline-block;
      border: 1px solid $color-highlight;
      background-color: $color-highlight;
      color: #fff;
      padding: 12px 30px;
      border-radius: 22px;
      text-transform: uppercase;
      transition: background-color 0.1s linear;

      &:hover {
        color: #fff;
        background-color: $color-highlight-hover;
      }

    }

  }

}

#block-views-events-block-1 {
  display: none;
}

#block-mdblocks-events {
  margin-bottom: 70px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  padding: 50px 0;

  .content {
    width: 1170px;
    margin: 0 auto;
    font-size: 16px;
    clear: both;
  }

  .past-events {
    display: none!important;
  }

  .future-view, .past-view {
    display: none;

    &.active {
      display: block;
    }

  }

  .view-story-main {
    width: 702px;
    min-height: 390px;
    float: left;
    text-align: left;
    margin-right: 30px;
    display: none;

    table {

      tbody {
        border: none;
      }

    }

    &.active {
      display: block;
    }

    img {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }

    .title-date-day {
      font-family: $font2-bold;
      font-size: 43px;
      width: 5%;
      padding-right: 10px;
      text-align: left;
    }

    .title-date-rest {
      font-family: $font2-bold;
      font-size: 17px;
      color: $color-highlight;
      text-align: left;
      width: 5%;
      padding-right: 20px;
    }

    .story-title-text {
      text-align: left;
      font-family: $font2-bold;
      font-size: 17px;
    }

  }

  .view-story-others {
    width: 438px;
    float: left;

    .view-story-row {
      text-align: left;
      width: 438px;
      float: left;
      margin: 0;
      border-bottom: 8px solid transparent;
      min-height: 130px;
      transition: border-bottom 0.1s linear;

      img {
        display: none;
      }

      &:nth-child(2),
      &:nth-child(3) {

        img {
          display: block;
        }

      }


      .view-theme {
        text-transform: uppercase;
        color: $color-font-gray;
        font-size: 12px;
        font-family: $font-light;
        letter-spacing: 1px;
      }

      .view-title {
        padding: 15px 15px 15px;
        border-bottom: 1px solid $color-border;
        background-color: transparent;
        transition: background-color 0.1s linear;

        span {
          display: block;
          font-family: $font-bold;
        }

        a {
          transition: none;
        }

      }

      &:hover {

        .view-title {
          background-color: $color-highlight-more;

          a, span {
            color: #fff;
          }


        }

      }

      &.active {

        .view-title {
          background-color: $color-highlight;

          a, span {
            color: #fff;
          }


        }

      }

    }

  }

  .view-menu {
    text-align: center;
    clear: both;

    .show-all {
      display: inline-block;
      border: 1px solid $color-highlight;
      background-color: $color-highlight;
      color: #fff;
      padding: 12px 30px;
      border-radius: 22px;
      text-transform: uppercase;
      transition: background-color 0.1s linear;

      &:hover {
        color: #fff;
        background-color: $color-highlight-hover;
      }

    }

  }


  .past-view {
    margin: 0 -15px;

    .view-story-row {
      width: 376px;
      float: left;
      margin: 0 12px 15px;
      padding-bottom: 15px;
      border-bottom: 8px solid transparent;
      min-height: 420px;
      transition: border-bottom 100ms linear;

      &:hover {
        border-bottom: 8px solid $color-highlight-less;
      }

      .view-theme {
        margin-top: 20px;
        text-transform: uppercase;
        color: $color-font-gray;
        font-size: 12px;
        font-family: $font-light;
        letter-spacing: 1px;
      }

      .view-title {
        margin-top: 20px;

        span {
          margin-right: 10px;
        }

      }

    }

  }


}

.view-theme-news,
.view-news,
.view-consultation,
.view-events,
.view-partners,
.view-experts,
.view-ecoblog,
.view-interviews,
.page-search,
.view-articles {
    overflow-x: hidden;

  .view-content {
    margin: 1px -12px 70px;
    font-size: 16px;
    clear: both;
  }

  .views-row {
    width: 376px;
    float: left;
    margin: 0 11px 15px;
    padding-bottom: 15px;
    border-bottom: 8px solid transparent;
    min-height: 420px;
    transition: border-bottom 100ms linear;

    img {
      width: 376px;
      height: 257px;
    }

    &:hover {
      border-bottom: 8px solid $color-highlight-less;
    }

    .field-name-field-field-image-event {
      margin-bottom: 0;
    }

    .field-name-field-event-type {
      margin-top: 20px;
      text-transform: uppercase;
      color: $color-font-gray;
      font-size: 12px;
      font-family: $font-light;
      letter-spacing: 1px;
    }

    .view-title {
      margin-top: 10px;

      span {
        margin-right: 10px;
      }

    }

    .field-name-post-date,
    .field-name-field-date-begin {
      color: $color-highlight-red;
      margin-top: 5px;
    }

    .field-name-field-theme {
      text-transform: uppercase;
      color: $color-font-gray;
      font-size: 12px;
      font-family: $font-light;
      letter-spacing: 1px;

    }

  }

  .item-list {
    clear: both;
    text-align: center;

    li {
      display: inline-block;
      line-height: 38px;
      height: 38px;
      width: 41px;
      vertical-align: top;
      margin: 0 2px;
      padding: 0;

      &.pager-current,
      a {
        display: inline-block;
        border: 1px solid $color-border;
        border-radius: 3px;
        line-height: 38px;
        height: 38px;
        width: 41px;
      }

      a {
        background-color: transparent;
        transition: background-color 0.1s linear;
        &:hover {
          transition: background-color 0.1s linear;
          background-color: $color-highlight;
          color: $color-highlight-color;
        }
      }

      &.pager-current {
        background-color: $color-highlight;
        color: $color-highlight-color;
      }

    }

  }

}

.view-interviews {
  .views-row {
    min-height: 60px;
    margin: 0 15px 5px;
    padding-bottom: 0px;
  }
}

.view-partners {

  .views-row {
    min-height: 460px;

    .views-field-title {
      text-transform: uppercase;
      margin: 20px 0;

      a {
        color: $color-highlight;
      }

    }

    img {
      border: 1px solid $color-border;
      box-sizing: border-box;
    }

  }

}

.view-experts,
.view-ecoblog {

  .views-row {
    min-height: 320px;
    margin: 0 11px 35px;

    .views-field-title {
      text-transform: uppercase;
      margin: 20px 0 0px;

      a {
        color: $color-highlight;
      }

    }

    img {
      border: 1px solid $color-border;
      box-sizing: border-box;
    }

  }

}

.view-events {

  .views-row {

    .field-name-post-date,
    .field-name-field-date-begin {
      color: $color-highlight-red;
      margin-top: 5px;
    }

  }

}


.node {

  h1 {
    font-family: $font-light;
    font-size: 32px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .field-name-field-img-partner {
    float: left;

    img {
      border: 1px solid $color-border;
      margin-right: 30px;
      margin-bottom: 30px;
    }

  }

  &.node-full .field-name-body {

    //img {
    //  max-width: 100%;
    //  height: auto!important;
    //}

  }

  .post-date,
  &.node-full .field-name-field-date-begin {
      color: $color-highlight-red;
      margin-bottom: 38px;
  }

  &.node-full .field-name-field-theme,
  .story-theme {
    text-transform: uppercase;
    color: $color-font-gray;
    font-size: 12px;
    font-family: $font-light;
    letter-spacing: 1px;
  }

  .field-name-field-image,
  .field-name-field-news-img,
  .field-name-field-field-image-event,
  .field-name-field-expert-image{
    margin-bottom: 30px;
  }

  .field-name-body,
  .field-name-field-first-paragraph,
  #block-mdblocks-banner-content {
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 20px;

    p {
      margin-bottom: 15px;
    }

  }

  .field-name-field-first-paragraph {
    padding-bottom: 0;
  }

}

#block-mdblocks-journal {

  .journal-contents {

    p {
      margin-bottom: 15px;
    }

  }

}

#user-login {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 10px;

    .form-item {
        margin-bottom: 30px;
    }

    label {
        display: block;
        margin-bottom: 5px;
        color: $color-font-gray;
    }

    input {
        border: 1px solid $color-border;
        height: 40px;
        width: 100%;
        margin-bottom: 5px;
        box-sizing: border-box;
        padding: 0 15px;
    }

    .description {
        color: $color-font-gray;
    }

}

.region-popups {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;

    .node-edit-link {
        position: absolute;
    }

    .block {
        border: 10px solid rgba(0,0,0,0.5);
        left: 50%;
        top: 50%;
        width: 470px;
        height: 688px;
        position: absolute;
        border-radius: 3px;
        margin-top: -375px;
        margin-left: -259px;
        box-sizing: border-box;
        display: none;

        #webform-ajax-wrapper-1571 {

            .messages {
                display: none;
            }

        }

        .node-webform {

            h2 {
                display: none;
            }

        }

        & > .content {
            height: 100%;
            background-color: $color-popup-bg;
            padding: 55px 43px 0;

            label {
                display: none;
            }

            input[type=text],
            input[type=email] {
                width: 100%;
                box-sizing: border-box;
                height: 40px;
                padding: 0 20px;
                border: 1px solid $color-border;
                outline: none;
                margin-bottom: 20px;

                &:focus {
                    border: 1px solid $color-highlight;
                }

                &.error {
                    border: 1px solid $color-highlight-red;
                }

            }

            .form-submit {
                width: 250px;
                margin: 18px auto 0;
                background-color: $color-highlight;
                color: $color-highlight-color;
                font-family: $font-bold;
                padding: 12px 30px;
                border-radius: 22px;
                border: none;
                outline: none;
                cursor: pointer;
                display: block;
                text-transform: uppercase;
            }

        }

        .popup-close-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            width: 34px;
            height: 34px;

            .icon {
              font-size: 34px;
              color: $color-highlight;

              &:hover {
                color: $color-highlight-hover;
              }

            }

        }

        .popup-title {
            font-size: 30px;
            font-family: $font-bold;
            text-align: center;
            line-height: 1.1;
        }

        .popup-subtitle {
            font-size: 30px;
            font-family: $font-light;
            text-align: center;
            color: $color-highlight;
            line-height: 1.1;
            margin-bottom: 30px;
        }

        .links {
            display: none;
        }

        .confirm-button {

            width: 250px;
            margin: 18px auto 0;
            background-color: $color-highlight;
            color: $color-highlight-color;
            font-family: $font-bold;
            padding: 12px 30px;
            border-radius: 22px;
            border: none;
            outline: none;
            cursor: pointer;
            display: block;
            text-align: center;
            text-transform: uppercase;
        }

    }

}

#block-mdblocks-popup-banner {
  width: 720px;
  height: 420px;
  margin-left: -360px;
  margin-top: -210px;

  .content {
    padding: 0;
  }
}

#block-search-form .container-inline,
.page-search .search-form {
    position: relative;

    .form-item-search-block-form {
        vertical-align: top;
        text-align: left;
    }

    label {
        display: none;
    }

    input[type=text] {
        vertical-align: top;
        display: block;
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        padding: 0 20px;
        border: 1px solid $color-border;
        outline: none;
        border-radius: 20px;

        &:focus {
            border: 1px solid $color-highlight;
        }

        &.error {
            border: 1px solid $color-highlight-red;
        }

    }

    .form-actions {
        display: block;
        position: absolute;
        right: 5px;
        top: 0 ;
    }

    .form-submit {
      border: none;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      font-size: 0;
      background: url(../../images/search-icon.png) no-repeat center center transparent;
      cursor: pointer;
    }

}

.region-search {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.page-search .search-form {
    display: none;
}

.search-form-btn {
    height: 40px;
    width: 40px;
    background: url(../../images/search-icon.png) no-repeat center center transparent;
    cursor: pointer;
    border-radius: 20px;
}

.page-search .search-form-btn {
    display: none;
}

#node-6 {

    width: 100%!important;

    .field-name-body {
        margin-bottom: 100px;
    }

}

#block-mdblocks-google-map {
    font-size: 16px;
    line-height: 1.5;

    .content {
        background-color: $color-popup-bg;
    }

    .email_contact {
        width: 1170px;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 80px;

        a {
            color: $color-highlight;
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }

        }

    }

}

.node-arhiv {

  .content {
    width: 1099px;
    box-sizing: border-box;
    border: 21px solid $color-highlight-less;
    margin-left: 71px;
    padding-left: 310px;
    padding-top: 60px;
    padding-right: 60px;
    padding-bottom: 40px;
    position: relative;

    .field-name-field-img-arhiv {
      position: absolute;
      left: -92px;
      top: 60px;
    }

    .journal-index {
      font-weight: normal;
      position: absolute;
      left: 100px;
      width: 270px;

      span {
        color: $color-highlight;
        font-family: $font-bold;
      }

      ul {
        padding-left: 15px;
        list-style-position: inherit;
      }

      ul li {
        list-style-type: disc;
      }
    }

    .journal-preview-button {
      float: right;
      width: 306px;
      height: 115px;
      display: block;
      position: relative;
      right: -60px;
      background: url(../../images/eye-icon.png) no-repeat 225px 40px $color-highlight-less;
      text-transform: uppercase;
      color: $color-font;
      font-family: $font-bold;
      line-height: 1.4;
      padding: 40px 95px 0 0;
      text-align: right;
      box-sizing: border-box;
    }

  }
}

.node-full {

  .links-wrapper {
      margin-bottom: 30px;

      .archive-link {
        color: $color-font;
        font-family: $font-light;
        text-transform: uppercase;
        margin-right: 25px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA+gAAAAKCAYAAAAnx3TwAAAAUUlEQVR42u3XQREAAAQAMPqHdRIQg8fWYlnVEwAAAMCpFHQAAAAQdAAAAEDQAQAAQNABAAAAQQcAAABBBwAAAAQdAAAABB0AAAAQdAAAAPhnAdAhJhf3QaMCAAAAAElFTkSuQmCC);
        background-repeat: no-repeat;
        line-height: 1;
        background-position: 5px 8px;
        padding-right: 10px;
        padding-bottom: 3px;

        &:hover {
          text-decoration: none;
        }

        &.select-green {
          background: $color-highlight;
          color: #fff;
          font-family: $font-bold;
          padding: 5px;

          &:hover {
            background: $color-highlight-hover;
          }
        }
      }
  }

}


.archive-wrapper {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    min-height: 65px;

    .year-wrapper {
        display: none;

        &.active {
            display: block;
        }

        .journal-link {
            color: $color-font;
            text-decoration: none;
            font-size: 18px;
            min-width: 80px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-family: $font-light;
            margin-top: 8px;

            &.empty-journal-link {
                color: $color-font-gray;
                opacity: 0.3;
            }

            &.active {
                margin-top: 0;
                color: $color-highlight;
                font-size: 28px;
            }

        }

        .year-selector {
            margin-top: 3px;
            float: right;
            font-size: 28px;
            color: $color-highlight;

            .prev-button,
            .next-button {
                color: $color-font;
                cursor: pointer;
                margin: 0 4px;
            }

        }

    }

}

.view-forms,
.view-npb {

    .views-exposed-form {
        max-width: 550px;
        margin-bottom: 50px;
    }

    label {
        color: $color-font-gray;
        margin-bottom: 5px;
        display: inline-block;
    }

    input[type=text],
    input[type=email] {
        width: 250px;
        box-sizing: border-box;
        height: 40px;
        padding: 0 20px;
        border: 1px solid $color-border;
        outline: none;
        margin-bottom: 20px;

        &:focus {
            border: 1px solid $color-highlight;
        }

        &.error {
            border: 1px solid $color-highlight-red;
        }

    }

    select {
        width: 250px;
        box-sizing: border-box;
        height: 40px;
        padding: 0 20px;
        border: 1px solid $color-border;
        outline: none;
        margin-bottom: 20px;

        &:focus {
            border: 1px solid $color-highlight;
        }

        &.error {
            border: 1px solid $color-highlight-red;
        }

    }

    .form-submit {
        width: 200px;
        margin-top: 0!important;
        background-color: $color-highlight;
        color: $color-highlight-color;
        font-family: $font-bold;
        padding: 12px 30px;
        border-radius: 22px;
        border: none;
        outline: none;
        cursor: pointer;
        display: block;
        text-transform: uppercase;
    }

    .views-row {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-border;

        .field-name-title {
            font-size: 18px;
            font-family: $font-light;
            margin-bottom: 10px;
        }

        .field-name-node-link {

            a {
                text-decoration: none;
                color: $color-highlight;

                &:hover {
                    text-decoration: underline;
                }

            }

        }

        .field-name-post-date,
        .field-name-field-date-begin {
            margin-bottom: 10px;
        }

        .field-name-field-namber {
            margin-bottom: 10px;
        }

    }

    .item-list {
      clear: both;
      text-align: center;

      li {
        display: inline-block;
        line-height: 38px;
        height: 38px;
        width: 41px;
        vertical-align: top;
        margin: 0 2px;

        &.pager-current,
        a {
          display: inline-block;
          border: 1px solid $color-border;
          border-radius: 3px;
          line-height: 38px;
          height: 38px;
          width: 41px;
        }

        a {
          &:hover {
            background-color: $color-highlight;
            color: $color-highlight-color;
          }
        }

        &.pager-current {
          background-color: $color-highlight;
          color: $color-highlight-color;
        }

        &.pager-item, &.pager-ellipsis {

            @media ( max-width: 720px ) {
                display: none;
            }

        }

      }

    }

}

#block-block-34 {
    max-width: 870px;
    margin: 0 auto 30px;
}

#block-block-33 {

    margin: 0 -10px 30px;
    text-align: center;
    padding: 15px 0 5px;

    p {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 20px;
    }

}

.journal-wrapper {
    margin-bottom: 30px;

    .journal-content {
        width: 1099px;
        box-sizing: border-box;
        border: 21px solid $color-highlight-less;
        margin-left: 71px;
        padding-top: 60px;
        padding-right: 60px;
        position: relative;

        .journal-picture {
            position: absolute;
            left: -92px;
            top: 60px;
        }

      .journal-index {
        font-weight: normal;
        position: absolute;
        left: 100px;

        span {
          color: $color-highlight;
          font-family: $font-bold;
        }

        ul {
          padding-left: 15px;
        }

        ul li {
          list-style-type: disc;
        }
      }

    }

    .journal-contents {
        margin-left: 310px;
        width: 380px;
        line-height: 1.5;
        float: left;
        border-right: 1px solid $color-border;
        padding-right: 50px;

        h3 {
            margin-bottom: 20px;
        }

    }

    .journal-soon-contents {
        width: 285px;
        float: right;
        line-height: 1.5;
        border-top: 1px solid $color-border;

        .journal-soon-contents-title {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 16px;
            font-family: $font-bold;
            text-transform: uppercase;

            .title-square {
                width: 13px;
                height: 13px;
                position: absolute;
                background-color: $color-highlight;
                margin-left: -22px;
                margin-top: 4px;
            }

        }

        .journal-soon-contents-body {

            div {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid $color-border;
            }

        }

    }

    .journal-footer {
        clear: both;
        text-align: center;
        padding-top: 55px;
        padding-bottom: 70px;

        a {
            display: inline-block;
            border: 1px solid $color-highlight;
            color: $color-font;
            padding: 12px 30px;
            border-radius: 22px;
            text-transform: uppercase;
            transition: background-color 0.3s ease-in-out;

            &:hover {
              background-color: $color-highlight;
              color: $color-highlight-color;
            }

        }

    }

}
.page-events #block-system-main {
  margin-bottom: 0;
}

#block-system-main {
  margin-bottom: 20px;

  .content {

    & > .node-webform {

      h2 {
        display: none;
      }


      & > .content {
        padding: 55px 43px 0;

        .form-item {
          margin-bottom: 20px;

          &.form-type-radio {
            margin-bottom: 0px;
          }
        }

        label {
          margin-bottom: 5px;
          font-size: 16px;
          display: inline-block;

          .form-required {
            color: $color-highlight-red;
          }

        }

        input[type=text],
        input[type=email] {
          width: 100%;
          box-sizing: border-box;
          height: 40px;
          padding: 0 20px;
          border: 1px solid $color-border;
          outline: none;
          font-size: 16px;

          &:focus {
            border: 1px solid $color-highlight;
          }

          &.error {
            border: 1px solid $color-highlight-red;
          }

        }

        .form-submit {
          width: 250px;
          margin-top: 18px;
          background-color: $color-highlight;
          color: $color-highlight-color;
          font-family: $font-bold;
          padding: 12px 30px;
          border-radius: 22px;
          border: none;
          outline: none;
          cursor: pointer;
          display: block;
          text-transform: uppercase;
        }

      }

    }

  }
}

#block-mdblocks-more-materials {
  margin-bottom: 70px;
  font-size: 16px;
  border-top: 8px solid $color-highlight-less;
  padding-top: 30px;

  h2 {
    background: none;
    font-size: 16px;
    margin-bottom: 10px;
  }

  ul {
    margin-left: 18px;

    li {
      list-style-type: disc;
      padding-bottom: 10px;
    }
  }



}

.show_gerb .field-name-body{
  background: url("../../images/gerb.png") no-repeat top center;
  padding-top: 80px;
}

h1.future-events {
  margin-right: 20px!important;
}

.future-events,
.past-events {
    margin-right: 20px;
    background-image: none!important;
    text-decoration: underline;
    font-weight: normal;
    cursor: pointer;

    &.active {
        cursor: default;
        font-family: $font-bold;
        text-decoration: none;
    }

}

.ajax-progress-throbber {
    display: none!important;
}

.backlink {
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    margin-top: 8px;

    a {
      color: $color-highlight;
      display: inline-block;
      position: relative;
      top: 4px;
    }

    .arrow-line {
      position: absolute;
    	background: #fff;
    	border-top: 1px solid #ccc;
      width: 60px;
      display: inline-block;
      vertical-align: top;
      z-index: 2;
      margin-left: -8px;
    }

    .long-arrow-left {
    	position: relative;
    	background: #fff;
    	border: none;
        display: inline-block;
        padding-right: 65px;
    }

    .long-arrow-left:after, .long-arrow-left:before {
    	right: 100%;
    	top: 50%;
    	border-style: solid;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    }

    .long-arrow-left:after {
    	border-color: rgba(0, 0, 0, 0);
    	border-right-color: #fff;
    	border-width: 7px;
    	margin-top: -7px;
    }

    .long-arrow-left:before {
    	border-color: rgba(204, 204, 204, 0);
    	border-right-color: #ccc;
    	border-width: 8px;
    	margin-top: -8px;
    }

}

.field-name-field-first-paragraph {
    margin-bottom: 30px;
}

.field-name-upload {
    margin-bottom: 30px;
    text-align: left;

    td, th {
        padding: 5px;
    }

}

#block-block-40 {
  clear: both;
  margin-bottom: 50px;
}

.btn-b-green {
  width: 250px;
  background-color: $color-highlight;
  color: $color-highlight-color;
  font-family: $font-regular;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 22px;
  border: none;
  outline: none;
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background-color: $color-highlight-hover;
    color: $color-highlight-color;
    text-decoration: none;
  }
}

.node-full .block-btns,
.block-btns {
  margin-bottom: 15px;

  a {
    background-color: $color-highlight;
    color: $color-highlight-color !important;
    font-family: $font-regular;
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 22px;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 5px 10px 0px;

    &:hover {
      background-color: $color-highlight-hover;
      color: $color-highlight-color;
      text-decoration: none;
    }

  }

}

#block-block-41 {
  margin-top: 40px;
}

#block-mdblocks-slider {
  clear: both;
  margin-bottom: 50px;
}

.cycle-slide {
  text-align: center;
}

.global-link {
  display: block;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 0;
}

.more-social-pages {
  font-size: 12px;
  font-style: italic;
  padding: 0px 0 30px 0;
}



/**********timer*********************/
.banner-timer {
  position: relative;
  height: 317px;
  width: 270px;
  background: url("../../images/fs.png") no-repeat;
}
.timer-wrapper {
  position: absolute;
  top: 145px;
  height: 70px;
  width: 270px;
  padding: 10px 0 0 10px;
  text-align: right;

  .timer span.timer-dot {
    background: none;
    border: none;
    display: inline-block;
    margin: 0px 5px;
    color: #519391;
  }
}



figure, figcaption {
  display: block;
}

.transition {
  -webkit-transition: top 400ms linear;
  -moz-transition: top 400ms linear;
  -ms-transition: top 400ms linear;
  -o-transition: top 400ms linear;
  transition: top 400ms linear;
}

.timeTo {
  font-family: $font-regular;
  font-size: 28px;
  line-height: 108%;
  height: 32px;
}

.timeTo span {
  vertical-align: top;
}

.timeTo.timeTo-white div {
  color: black;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 38%, #cccccc 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(38%,#ffffff), color-stop(100%,#cccccc)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 38%,#cccccc 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 38%,#cccccc 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 38%,#cccccc 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 38%,#cccccc 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}
.timeTo.timeTo-black div {
  color: #519391;
}

.timeTo.timeTo-black .timeTo-alert {
  background: #a74444; /* Old browsers */
  background: -moz-linear-gradient(top, #a74444 0%, #3f0000 67%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#a74444), color-stop(67%,#3f0000)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #a74444 0%,#3f0000 67%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #a74444 0%,#3f0000 67%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #a74444 0%,#3f0000 67%); /* IE10+ */
  background: linear-gradient(to bottom, #a74444 0%,#3f0000 67%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a74444', endColorstr='#3f0000',GradientType=0 ); /* IE6-9 */
}

.timeTo.timeTo-white .timeTo-alert {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 35%, #e17373 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%,#ffffff), color-stop(100%,#e17373)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 35%,#e17373 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 35%,#e17373 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 35%,#e17373 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 35%,#e17373 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e17373',GradientType=0 ); /* IE6-9 */
}

.timeTo figure {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.timeTo figcaption {
  text-align: center;
  line-height: 80%;
  font-weight: normal;
  color: #519391;
}

.timeTo div {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 30px;
  overflow: hidden;
  background: none;
}
.timeTo div.first {

}

.timeTo ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 3px;
}

.timeTo ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Facebook like box*/
.fixed-likebox {
  display: none;
  position: fixed;
  background: #fff;
  bottom: 15px;
  left: -400px;
  padding: 5px;
  border: 1px solid #8c8282;
  z-index: 9000;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 1px #b4aaaa;

  &.js-likebox {
    width: 280px;

    p {
      margin: 10px 20px;
      text-align:left;
      font-size: 12px;

      &.hide-box {
        display: block;
        padding-bottom: 10px;
        color: #8c8282;
        cursor: pointer;
        text-align: center;
      }
    }
  }

  .d-close {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url('data:image/gif;base64,R0lGODlhFAAUAJECAOvm5rSqqv///wAAACH5BAEAAAIALAAAAAAUABQAAAI5lI+ZEOoXgGBPxUOruSg/vjQOqHiQpJlkla1p4GoCLFuAKXP4hxp74vpteqERsSQ61TwxR+YokxQAADs=') no-repeat 0 0;
    right: 10px;
    top: 15px;
    cursor: pointer;
  }

  .fb-xfbml-parse-ignore a {
    background: #3b5998;
  }

}

.not-front .main-banners-wrapper {
  height: 350px;

  .main-banners {
    height: 350px;

    .main-banner {
      height: 350px;
      padding-top: 100px;

      .banner-content {

        .main-banner-title {
          font-size: 22px;
        }

        .main-banner-button {
          line-height: 33px;
          height: 33px;
          padding: 0 40px;
        }

      }

    }

    .main-banners-switch {
      bottom: 100px;
    }

  }

}

.main-banners-wrapper {
  height: 400px;
  background-color: #ccc;
  position: relative;

  .main-banners {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 400px;

    .section {
      width: 1170px;
      margin: 0 auto;
    }

    .main-banners-switch {
      position: absolute;
      bottom: 100px;

      .main-banner-switch {
        width: 10px;
        height: 10px;
        border: 2px solid #fff;
        border-radius: 5px;
        margin-right: 20px;
        float: left;
        cursor: pointer;

        &.active {
          cursor: default;
          background-color: #fff;
        }

      }


    }

    .main-banner {
      width: 100%;
      height: 400px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 115px;
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.1s linear;

      &.active {
        visibility: visible;
        opacity: 1;
      }

      .banner-content {
        width: 1170px;
        margin: 0 auto;

        .main-banner-title {
          display: block;
          float: left;
          color: #fff;
          font-size: 28px;
          font-family: $font-bold;
          line-height: 1.1;
          margin-top: -6px;
          max-width: 75%;
        }

        .main-banner-button {
          display: block;
          float: right;
          font-family: $font-bold;
          line-height: 43px;
          height: 43px;
          border-radius: 28px;
          text-align: center;
          color: #fff;
          background-color: $color-highlight;
          transition: background-color 0.1s linear;
          padding: 0 50px;
          max-width: 25%;

          &:hover {
            background-color: $color-highlight-hover;
          }

        }

      }

    }

  }

}

.main-new {
  width: 376px;
  height: 372px;
  box-sizing: border-box;
  padding: 35px 25px;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  margin: 0 10px 20px;
  text-shadow: 1px 1px 1px transparent;
  transition: text-shadow 0.05s linear;
  position: relative;

  .main-new-bg {
    filter: brightness(25%);
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.05s linear;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  &:hover {
    text-shadow: 1px 1px 1px #000;
    color: #fff!important;
    background-color: transparent;

    .main-new-bg {
      opacity: 0.9;
      visibility: visible;
    }

    .new-theme {

      a {
        color: #fff!important;

        &:hover {
          color: $color-highlight-hover!important;
        }

      }

    }

    .new-title {
      color: #fff!important;

      a {
        color: #fff!important;

        &:hover {
          color: $color-a-hover!important;
        }

      }

      .highlight-red {
        color: #fff!important;
        opacity: 0.6;
      }

    }

    .new-body {
      color: #fff!important;
      word-break: break-word;

      a {
        color: #fff!important;

        &:hover {
          color: $color-a-hover!important;
        }

      }

      .highlight-red {
        color: #fff!important;
        opacity: 0.6;
      }

    }

  }

  .new-theme {
    margin-bottom: 20px;

    a {
      color: $color-highlight;
      text-transform: uppercase;
      font-size: 14px;

      &:hover {
        color: $color-highlight-hover;
      }

    }

  }

  .new-title {
    font-size: 18px;
    font-family: $font-bold;
    margin-bottom: 20px;

    a {
      transition: none;
    }

    .highlight-red {
      color: $color-font-gray;
      font-family: $font-regular;
      font-size: 16px;
    }

  }

  .new-body {
    margin-bottom: 20px;
  }

}

#block-mdblocks-projects {

  .content {
    margin: 0 -10px;
  }

  .projects-title {
    font-size: 28px;
    position: relative;
    background-repeat: no-repeat;
    font-family: $font-bold;
    margin: 0;
    line-height: 1;
    background-position: 7px 14px;
    padding-right: 10px;
    padding-bottom: 3px;
    margin-bottom: 50px;
    text-align: center
  }

  .project-item {
    position: relative;
    display: inline-block;
    width: 376px;
    height: 257px;
    margin: 0 10px 20px 10px;
    background: url(../../images/project-item-bg.jpg) no-repeat 0 0 transparent;
    text-align: center;
    vertical-align: top;

    .project-item-title {
      color: #fff;
      font-size: 22px;
      font-family: $font-bold;
      padding: 0 30px;
    }

    .project-item-icon {
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      margin: 30px auto 10px;
      width: 100%;
      height: 125px;

      &.project-item-icon-01 {
        background-image: url(../../images/project-icon-01.png)
      }

      &.project-item-icon-02 {
        background-image: url(../../images/project-icon-02.png)
      }

      &.project-item-icon-03 {
        background-image: url(../../images/project-icon-03.png)
      }

      &.project-item-icon-04 {
        background-image: url(../../images/project-icon-04.png)
      }

      &.project-item-icon-05 {
        background-image: url(../../images/project-icon-05.png)
      }

      &.project-item-icon-06 {
        background-image: url(../../images/project-icon-06.png)
      }

      &.project-item-icon-07 {
        background-image: url(../../images/project-icon-07.png)
      }

      &.project-item-icon-08 {
        background-image: url(../../images/project-icon-08.png)
      }

      &.project-item-icon-09 {
        background-image: url(../../images/project-icon-09.png)
      }

    }

    .project-item-aligner {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      height: 257px;
      transition: opacity 0.1s linear;
      display: table;
      width: 100%;
    }

    .project-item-text {
      background-color: $color-highlight-more;
      color: #fff;
      font-size: 17px;
      font-family: $font-semibold;
      display: table-cell;
      vertical-align: middle;
      padding: 30px;
    }

    &:hover {

      .project-item-aligner {
        visibility: visible;
        opacity: 1;
      }

    }

  }

}

#block-mdblocks-solutions {

  .content {
    margin: 0 -10px;
  }

  h2 {
    font-size: 28px;
    position: relative;
    background-repeat: no-repeat;
    font-family: $font-bold;
    margin: 0;
    line-height: 1;
    background-position: 7px 14px;
    padding-right: 10px;
    padding-bottom: 3px;
    margin-bottom: 50px;
    text-align: center;
    width: 100%;
  }

  .main-solution {
    position: relative;
    display: inline-block;
    width: 376px;
    height: 257px;
    margin: 0 10px 20px 10px;
    text-align: center;
    vertical-align: top;

    img {
      cursor: pointer;
    }

  }

}

#block-mdblocks-subscribe {
  width: 100%!important;
  background-color: $color-highlight;
  font-family: $font-semibold;
  padding: 80px 0 100px;
  text-align: center;

  .subscribe-text {
    font-size: 46px;
    color: #fff;
    margin-bottom: 30px;
  }

  .subsrcibe-button {
    display: inline-block;
    border: 1px solid #fff;
    background-color: #fff;
    color: #000;
    padding: 12px 30px;
    border-radius: 22px;
    font-size: 14px;
    text-transform: uppercase;
    transition: background-color 0.1s linear;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

  }

}

.slideshow {

  a {

    img {
      width: 290px;
      height: 157px;
    }

  }

}

.form-item, .form-actions {
    margin: 0;
}

.auth-popups {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;

    .auth-messesage {
      font-size: 20px;
      margin-bottom: 40px;
    }

    .node-edit-link {
        position: absolute;
    }

    #link-enter,
    #link-register {

      background-color: $color-highlight;
      color: #fff;
      padding: 12px 50px;
      border: none;
      border-radius: 22px;
      text-transform: uppercase;
      font-size: 12px;
      cursor: pointer;
      display: inline-block;

      &:hover {
        background-color: $color-highlight-hover;
      }

    }

    .close-btn {
      position: absolute;
      top: 4px;
      right: 8px;
      font-size: 20px;
      color: $color-highlight;
      cursor: pointer;

      &:hover {
        color: $color-highlight-hover;
      }

    }

    #link-password {
      margin-top: 25px;
      display: inline-block;
      text-decoration: underline;
    }

    span {
      margin: 0 15px;
    }

    .auth-popup {
        border: 10px solid rgba(0,0,0,0.5);
        left: 50%;
        top: 50%;
        width: 474px;
        height: 280px;
        position: absolute;
        border-radius: 3px;
        margin-top: -140px;
        margin-left: -257px;
        box-sizing: border-box;
        background-color: #fff;
        padding: 20px;
        display: none;

        #webform-ajax-wrapper-1571 {

            .messages {
                display: none;
            }

        }

        .node-webform {

            h2 {
                display: none;
            }

        }

        & > .content {
            height: 100%;
            background-color: $color-popup-bg;
            padding: 55px 43px 0;

            label {
                display: none;
            }

            input[type=text],
            input[type=email] {
                width: 100%;
                box-sizing: border-box;
                height: 40px;
                padding: 0 20px;
                border: 1px solid $color-border;
                outline: none;
                margin-bottom: 20px;

                &:focus {
                    border: 1px solid $color-highlight;
                }

                &.error {
                    border: 1px solid $color-highlight-red;
                }

            }

            .form-submit {
                width: 250px;
                margin: 18px auto 0;
                background-color: $color-highlight;
                color: $color-highlight-color;
                font-family: $font-bold;
                padding: 12px 30px;
                border-radius: 22px;
                border: none;
                outline: none;
                cursor: pointer;
                display: block;
                text-transform: uppercase;
            }

        }

        .popup-close-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            width: 34px;
            height: 34px;

            .icon {
              font-size: 34px;
              color: $color-highlight;

              &:hover {
                color: $color-highlight-hover;
              }

            }

        }

        .popup-title {
            font-size: 30px;
            font-family: $font-bold;
            text-align: center;
            line-height: 1.1;
        }

        .popup-subtitle {
            font-size: 30px;
            font-family: $font-light;
            text-align: center;
            color: $color-highlight;
            line-height: 1.1;
            margin-bottom: 30px;
        }

        .links {
            display: none;
        }

        .confirm-button {

            width: 250px;
            margin: 18px auto 0;
            background-color: $color-highlight;
            color: $color-highlight-color;
            font-family: $font-bold;
            padding: 12px 30px;
            border-radius: 22px;
            border: none;
            outline: none;
            cursor: pointer;
            display: block;
            text-align: center;
            text-transform: uppercase;
        }

    }

}

#user-register-form {
  margin: 0 auto;
  width: 576px;

  .form-item {
    width: 100%;
  }

  .form-type-textfield {
    margin-bottom: 20px;
  }

  .field-type-text {
    margin-bottom: 20px;
  }

  .form-type-textfield {

    label {
      display: inline-block;
      width: 180px;
    }

    input.form-text {
      display: inline-block;
      background: none;
      background-color: #fff;
      border: 1px solid #ccc;
      height: 36px;
    }

    .description {
      display: none;
    }

  }

  .form-type-password,
  .form-type-password-confirm {
    position: relative;
    min-height: 78px;

    input.password-field,
    input.password-confirm {
      width: 390px;
    }


    label {
      display: inline-block;
      width: 180px;
    }

    input.form-text {
      display: inline-block;
      background: none;
      background-color: #fff;
      border: 1px solid #ccc;
      height: 36px;
    }

    div.password-strength,
    div.password-confirm {
      position: absolute;
      bottom: 7px;
      right: 0;
      text-align: right;
    }

    .description {
      display: none;
    }

    .password-strength-title {
      margin-right: 15px;
    }

    .password-suggestions {
      display: none!important;
    }

  }

  #edit-submit {
    background-color: $color-highlight;
    color: #fff;
    padding: 12px 50px;
    border: none;
    border-radius: 22px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background-color: $color-highlight-hover;
    }

  }

}

.header-blocks .icon-user {
  display: inline-block;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 20px;
  overflow: hidden;
  font-size: 18px;
  margin-right: 10px;
  color: #000;
  background-color: #fff;
  text-align: center;
  line-height: 38px;
}

.header-user-block {
  display: table-cell;
  vertical-align: top;
  padding-right: 10px;


  a {
    color: #fff;

    &:hover {
      color: $color-highlight;
    }

  }

  .logout-link {
    margin-top: 10px;
    display: inline-block;
  }

}

#block-search-form {
  min-width: 320px;
}

.read-more-button {
  background-color: $color-highlight;
  color: #fff;
  padding: 12px 50px;
  border: none;
  border-radius: 22px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 50px;
  display: inline-block;

  &:hover {
    background-color: $color-highlight-hover;
  }

}

.form-type-checkbox {

  .form-checkbox {
    position: absolute;
  }

  .option {
    display: block;
    margin-left: 35px;
  }

}

.expert-types {
  margin-bottom: 20px;
  text-align: center;

  .expert-type {
    display: inline-block;
    padding: 10px 20px;
    background-color: $color-highlight;
    border-radius: 4px;
    margin: 0 10px;
    color: #fff;
    cursor: pointer;

    &.active, &:hover {
      background-color: $color-highlight-hover;
    }

  }

}

.expert-categories {
  margin-bottom: 20px;
  text-align: center;

  .expert-category {
    display: inline-block;
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid $color-highlight;
    border-radius: 8px;
    margin: 0 10px 10px;
    color: $color-a-default;
    cursor: pointer;

    &.active, &:hover {
      background-color: $color-highlight-hover;
      color: #fff;
    }

  }

}

.views-field-field-expert-image a, img {
  display: block;
}

.expert-info {
  margin-bottom: 15px;
}

.main-image-wrapper {
  float: left;
  width: 435px;
  margin-right: 15px;
  font-family: $font-bold;

  a {
    display: block;
    margin-bottom: 5px;

    img {
      display: block;
    }

  }


}

.expert-images-wrapper {
  float: left;
  width: 420px;
  margin-bottom: -18px;

  a {
    display: block;
    float: left;
    margin-left: 10px;
    margin-bottom: 18px;

    &:nth-child(odd) {
      margin-left: 0;
    }

    img {
      display: block;
    }

  }

}

.subscribe-buttons {
  margin: 30px 0;

  .subsrcibe-button {
    background-color: $color-highlight;
    float: left;
    color: $color-highlight-color;
    padding: 12px 50px;
    border-radius: 22px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background-color: $color-highlight-hover;
      color: $color-highlight-color;
    }

  }

  .telegram-button {
    background-color: $color-highlight;
    float: right;
    color: $color-highlight-color;
    padding: 12px 50px;
    border-radius: 22px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;

    &:visited {
      color: $color-highlight-color;
    }

    &:hover {
      background-color: $color-highlight-hover;
      color: $color-highlight-color;
      text-decoration: none;
    }

  }

}

.node-type-applicant {

  .block-system .content {
    p {
      margin: 0 0 1em;
    }

    ul {
      list-style: disc;
      list-style-position: inside;
      margin: 20px 0;

      li {
        margin-bottom: 10px;
      }

    }

    ol {
      list-style-position: inside;
      margin: 20px 0;
      padding: 0;

      li {
        margin-bottom: 10px;
      }

    }

  }

  .back-link-wrapper {
    text-align: center;
  }

  .back-link {
    background-color: #56af47;
    color: #fff !important;
    font-family: $font-regular;
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 22px;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 30px 5px 10px 0px;
  }

}

.view-applicants {
  margin-bottom: 50px;

  .views-exposed-widgets {

    select {
      max-width: 200px;
    }

  }

  ul {
    list-style: disc;
    list-style-position: inside;
    margin: 20px 0;

    li {
      margin-bottom: 10px;
    }

  }

  ol {
    list-style-position: inside;
    margin: 20px 0;
    padding: 0;

    li {
      margin-bottom: 10px;
    }

  }

  .views-row {
    margin-bottom: 20px;
  }

  .group-left {
    display: inline-block;
    width: 32%;
    vertical-align: top;
    text-align: left;

    h2 {
      font-size: 18px;
      margin-bottom: 20px;
    }

  }

  .group-middle {
    display: inline-block;
    width: 32%;
    vertical-align: top;
    text-align: left;
  }

  .group-right {
    display: inline-block;
    width: 32%;
    vertical-align: top;
    text-align: right;
  }

  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 17px;
  }

  .view-filters {
    margin-bottom: 30px;
  }

  .view-header {
    margin-bottom: 30px;
  }

}

.easy-breadcrumb {
  font-size: 12px;
  margin-bottom: 30px;
}

.node-type-applicant {

  .field {
    margin-bottom: 20px;
  }

  .field-name-title {

    h2 {
      margin-bottom: 10px;
    }

  }

  .application_details {
    display: none;
  }

}

.elementor-section  {
  p {
    margin: 0 0 1em;
  }
}

.star-off {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px 0px;
}
.star1-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -14px;
}
.star2-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -28px;
}
.star3-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -42px;
}
.star4-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -56px;
}
.star5-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -70px;
}
.star6-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -84px;
}
.star7-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -98px;
}
.star8-on {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  background: url('../../images/star.png') no-repeat 0px -112px;
}

.page-apply-message .column-content {
  float: none!important;
}

.apply-page {

  .ckeditor_links, .filter-wrapper {
    display: none!important;
  }

  .form-subtitle {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .form-item-title,
  .form-wrapper {
    margin-bottom: 20px;
  }

  h1 {
    font-family: $font-light;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .apply-message,
  .back-link-wrapper {
    text-align: center;
  }

  .back-link {
    background-color: #56af47;
    color: #fff !important;
    font-family: $font-regular;
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 22px;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 30px 5px 10px 0px;
  }

}

.donate {
  float: right;
  display: block;
  position: absolute;
  top: 5px;
  right: 0;

  img {
    display: block;
    height: 32px;
  }

}

.footer-address {
 a[href^="tel:"] {
   color: #fff;
 }
}

#block-mdblocks-banner-content {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 20px;

  p {
    margin-bottom: 15px;
  }
}
