@import "colors";
@import "fonts";

html {
	background-color: #ededed;
}

body.cke_editable {
	font-family: robotoRegular, sans-serif;
	background-color: #fff;
	padding: 0 142px;
	color: #666;
	max-width: 1061px;
	margin: 0 auto;
	line-height: 1.5;
	font-size: 16px;
}

h2,
.view-header h1,
.node-type-page .node-full h1 {
	font-size: 28px;
	position: relative;
	background-repeat: no-repeat;
	margin: 0;
	line-height: 1;
	background-position: 7px 14px;
	margin-bottom: 40px;
}


.not-front {

	h2,
	.view-header h1,
	.node-type-page .node-full h1 {
		font-size: 28px;
		position: relative;
		background-repeat: no-repeat;
		margin: 0;
		line-height: 1;
		background-position: 7px 14px;
		margin-bottom: 40px;
	}

}

.node-type-page .node-full h1 {

}

h3 {
	color: $color-highlight;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 18px;
}

h4 {
	color: $color-highlight;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 13px;
	margin: 0;
}

.cke_editable {

	ul, li, p {
		margin: 0;
		padding: 0;
	}

	p {
		margin-bottom: 10px;
		line-height: 1.5;
		font-size: 16px;
	}

	a, a:visited {
		text-decoration: none;
		color: #515151;
		text-transform: none;
		transition: color 0.3s ease-in-out;
	}

	table {
		border-collapse: collapse;
		border-color: grey;

		td {

			vertical-align: top;

			p {
				padding: 0 5px;
				font-size: 14px;
			}
		}

	}

}

table {
	margin: 0;
	padding: 0;
	width: 100%;
	border-collapse: collapse;

	th {
		font-weight: normal;
	}

}
