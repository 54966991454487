html {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	border: 0;
	min-width: 320px;

	&.popup-active {

		.region-popups {
			display: block;
		}

  }

	&.auth-popup-active {

		.auth-popups {
			display: block;
		}

  }

}

.all-wrapper {

}

.page-wrapper {
	background-color: $color-body-bg;
	margin: 0px auto;
	z-index: 1;
	position: relative;
}

.header {
	position: relative;
	z-index: 1;
	padding-top: 20px;

	.section {
		width: 1170px;
		margin: 0 auto;
		position: relative;
	}

	.header-blocks {
		float: right;
    max-width: 530px;
    height: 55px;
    width: 100%;
    display: table;
	}

}

.page-wrapper .main-menu {
	background-color: rgba(255,255,255, 0.7);
	bottom: 0;
	padding: 15px 0 0;
	z-index: 1;
	position: absolute;
	width: 100%;

	.section {
		margin: 0 auto;
		width: 1170px;
	}

	.menu {
		margin: 0 -13px;

		li {
			margin: 0 13px 15px;
			float: left;
		}
	}

}

.main-content-top {
	width: 1170px;
	margin: 0 auto;
	margin-top: 30px;
}



.not-front .main-wrapper {
	margin-top: 35px;

	.column-content {
	  max-width: 870px;
	  float: left;
	}

	.column-sidebar {
	  margin-top: 80px;
	  float: right;
	  min-width: 200px;
	}

}

.main-wrapper {
		margin-top: 40px;

		.main {
			margin: 0 auto;
			position: relative;
		}

}

.front .region-content .block {
	width: 1170px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 80px;
}

.not-front .main-wrapper {

		.main {
			width: 1170px;
		}

}

.no-sidebars .main-wrapper {

	.column-content {
		max-width: 100%;
	}

}

.page-node-6 {

	.footer-wrapper {
		margin-top: 0;
	}

}

.footer-wrapper {
	background-color: $color-footer-bg;
	color: $color-font-footer;
	margin-top: 30px;
	padding-top: 65px;
	padding-bottom: 95px;

	.footer {
		width: 1170px;
		margin: 0 auto;
		position: relative;

		.footer-info {

			.footer-address {
				float: right;
			}


		}

		.main-menu {
			border-bottom: 1px solid $color-border;
			background-color: transparent;
			margin-top: 30px;
		  padding-bottom: 25px;
			clear: both;
			position: relative;

			.menu {
				margin: 0 -33px;

				li {
					margin: 0 33px 15px;
					float: left;
				}
			}


		}

	}

	.mediapro-block {
		float: right;
		text-align: right;

		a {
			display: block;
			margin-bottom: 20px;
			color: $color-highlight;
		}

	}

	.subfooter-block {
		width: 1170px;
		margin: 0 auto;

		.copyrights-info {
			margin-top: 30px;
			color: $color-font-subfooter;
			text-align: left;
		}

		.copy-info {
			text-align: left;
			font-size: 10px;
			color: $color-font-subfooter;
			float: right;
			width: 600px;

			a {
				color: $color-font-subfooter;

				&:hover {
					color: $color-highlight;
				}
			}
		}

	}

}

#block-system-main > .content .node-full,
#block-system-main > .content .view-npb {
	width: 870px;
}

.node-type-arhiv #block-system-main > .content .node-full {
	width: auto;
}
